import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <>
      <footer className="bg-dark-gradient footer">
        <div className="footer-top">
          <div className="container">
            <div className="footer-border-bottom pb-6 mb-5">
              <div className="row">
                <div className="col-lg-4 col-xl-3">
                  <h4 className="text-white">Precision Meets Perfection</h4>
                </div>
                <div className="col-lg-4 col-xl-6">
                  <form className="d-flex flex-row mb-2 p-1 bg-white input-group">
                    <input
                      type="email"
                      className="form-control rounded-0 border-0"
                      placeholder="you@yoursite.com"
                    />
                    <button
                      className="btn btn-secondary rounded-0 flex-shrink-0"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </form>
                </div>
                <div className="col-lg-4 col-xl-3">
                  <div className="text-lg-end text-white">
                    <span className="h6">Call Us On</span>
                    <br />
                    <span className="h3">+91 705-071-8999</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-lg-3 my-4">
                <div className="mb-4">
                  <img src="static/img/logo-light.svg" title="" alt="" />
                </div>
                <div className="text-white-65 mb-4 text-white">
                  <p>Follow Us On</p>
                </div>
                <div className="nav footer-social-icon">
                  <Link to="#" className="link">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Link>
                  <Link to="#" className="link">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Link>
                  <Link to="#" className="link">
                    <FontAwesomeIcon icon={faInstagram} />
                  </Link>
                  <Link to="#" className="link">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </Link>
                </div>
              </div>
              <div className="col-sm-6 col-lg-2 my-4">
                <h5 className="text-white h6 mb-4">About</h5>
                <ul className="list-unstyled footer-links">
                  <li>
                    <Link className="link" to="/company">
                      Company
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/management">
                      Management
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-2 my-4">
                <h5 className="text-white h6 mb-4">Products</h5>
                <ul className="list-unstyled footer-links">
                  <li>
                    <Link className="link" to="/products/fintech">
                      Fintech Solution
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/products/Billing">
                      Billing & POS Solution
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/products/Voter">
                      Voter Management
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/products/AIS140">
                      AIS 140 VLTD
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/products/FleetManagement">
                      Fleet Management
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-2 my-4">
                <h5 className="text-white h6 mb-4">Support</h5>
                <ul className="list-unstyled footer-links">
                  <li>
                    <Link className="link" to="/contact">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/drivers">
                      MT Download
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/blog">
                      Product Support
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-2 my-4">
                <h5 className="text-white h6 mb-4">Legal</h5>
                <ul className="list-unstyled footer-links">
                  <li>
                    <Link className="link" to="/terms-of-use">
                      Terms of Use
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/privacy-policy">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link className="link" to="/cookie-policy">
                      Cookie Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom footer-border-top light py-3">
          <div className="container text-center">
            <p className="m-0">
              © {new Date().getFullYear()} copyright{" "}
              <Link to="#" className="text-reset link text-white">
                Mentation Technologies
              </Link>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
