import React, { useState } from "react";
import "../../../styles/Product.scss";
import mt580dp3 from "../../../../../assests/product/Billing&POS/MT800P-1.png";
import mt580dp4 from "../../../../../assests/product/Billing&POS/MT800P-2.png";
import mt580Dpdatasheet from "../../../../../assests/product/Billing&POS/Datasheet/MT800P data sheet.jpeg";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

export default function P800() {
  const [mainImage, setMainImage] = useState(mt580dp3);

  const images = [mt580dp3, mt580dp4];

  const handleThumbnailClick = (img) => {
    setMainImage(img);
  };

  return (
    <>
      <div>
        <div className="single-product">
          <section className="py-5">
            <div className="container">
              <div className="row gx-5">
                <aside className="col-lg-6 aside">
                  <div className="image-container">
                    <img
                      className="rounded-4 fit images"
                      src={mainImage}
                      alt="Main view"
                    />
                  </div>
                  <div className="d-flex justify-content-center mb-3">
                    {images.map((img, index) => (
                      <Link
                        key={index}
                        className="border mx-1 rounded-2 item-thumb"
                        onClick={() => handleThumbnailClick(img)}
                        to="#"
                      >
                        <img
                          width="60"
                          height="60"
                          className="rounded-2 thumbnail"
                          src={img}
                          alt={`Thumbnail ${index + 1}`}
                        />
                      </Link>
                    ))}
                  </div>
                </aside>
                <main className="col-lg-6 main">
                  <div className="ps-lg-3">
                    <h4 className="title text-dark">
                      Bluetooth Thermal Printer with 80mm paper size
                      compatibility
                    </h4>
                    <div className="d-flex flex-row my-3 product-name">
                      <p>MT800P2</p>
                    </div>

                    <div className="description">
                      <p>
                        The Mentation MT800P is a portable Bluetooth Thermal
                        Printer with 80mm paper size compatibility. This is
                        highly popular with the billing software solutions
                        providers, Electricity boards vendors, Ticketing
                        solution providers and authorized common service
                        centers.
                      </p>
                    </div>

                    <div className="key-features">
                      <h1>Key features typically include</h1>
                      <div className="list">
                        <ul className="features">
                          <li>
                            <strong>Bluetooth connectivity:</strong> <br /> For
                            easy pairing with smartphones, tablets, and
                            computers.
                          </li>
                          <li>
                            <strong>High printing speed:</strong> <br />
                            Efficiently produces receipts and other documents.
                          </li>
                          <li>
                            <strong>Lithium ion Battery:</strong> <br />
                            High Battery Backup of 2600mAh for long printing
                            hours.
                          </li>
                          <li>
                            <strong>Compact and lightweight design:</strong>{" "}
                            <br />
                            Ideal for portability.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="row short-heighlight">
                      <p className="des">Get To know More about this Product</p>
                      <span>
                        <Link
                          to={mt580Dpdatasheet}
                          target="_blank"
                          download="MT800P"
                          className="link"
                        >
                          Download PDF
                        </Link>
                      </span>
                    </div>
                    <hr />
                  </div>
                </main>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
