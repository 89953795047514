import React from "react";
import "./Blog.scss";
import { Link } from "react-router-dom";
export default function Blog() {
  return (
    <>
      <div className="blog-container">
        <div className="container">
          <div className="row mt-5">
            <h1>Experience Our Products Installation Stories & Videos</h1>
            <div
              className="col-md-6 col-lg-4 mt-5 wow fadeInUp"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeInUp",
              }}
            >
              <div className="blog-grid">
                <div className="blog-grid-img position-relative">
                  <iframe
                    width="100%"
                    height="300"
                    src="https://www.youtube.com/embed/Zy-mYCUEHbU?si=2R6Fr3BfdkdVlPPM"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="blog-grid-text p-4">
                  <p className="display-30">
                    How to use Thermal Printer to print from Laptop using
                    Bluetooth Interface
                  </p>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div
              className="col-md-6 col-lg-4 mt-5 wow fadeInUp"
              data-wow-delay=".2s"
              style={{
                visibility: "visible",
                animationDelay: "0.2s",
                animationName: "fadeInUp",
              }}
            >
              <div className="blog-grid">
                <div className="blog-grid-img position-relative">
                  <iframe
                    width="100%"
                    height="300"
                    src="https://www.youtube.com/embed/MvDS1K_CSko?si=Nkd8yUCX-eDzPxUl"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="blog-grid-text p-4">
                  <p className="display-30">
                    Internet Explorer Print Page Setup for Thermal Printer
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
