import React, { useEffect } from "react";
import "./Contact.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import contact from "../../../assests/contact/contact.svg";
import location from "../../../assests/contact/location.svg";
import mail from "../../../assests/contact/mail.svg";
// import contactBanner1 from "../../assests/Banner/contact-page/banner-bottom-1.png"
// import contactBanner2 from "../../assests/Banner/contact-page/banner-bottom-2.png"

export default function Contact() {
  return (
    <>
      <div className="contact-main-banner">
        <div className="contact-banner">
          <div className="overlay"></div>
          <Container fluid className="content">
            <h1>Reach Out and Connect with Us Your Queries, Our Priority!</h1>
            <p>
              We invite you to reach out and connect with us. Your queries and
              concerns are our top priority, and we're committed to providing
              you with prompt and attentive responses.
            </p>
          </Container>
        </div>
        {/* Adreess */}

        <Container className="my-5">
          <Row className="g-4">
            <Col xs={12} sm={6} md={4} lg={4}>
              <Card className="product-card">
                <div className="img-section">
                  <img src={location} alt="Contact" />
                </div>
                <Card.Body className="card-body">
                  <Card.Title className="card-title">Visit Us</Card.Title>
                  <Card.Text className="card-des">
                    Office 61, Surya Shopping Center, Shrusti Sector 5, Mira
                    Road (E) Thane 401107
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} lg={4}>
              <Card className="product-card">
                <div className="img-section">
                  <img src={contact} alt="Location" />
                </div>
                <Card.Body className="card-body">
                  <Card.Title className="card-title">Contact Us</Card.Title>
                  <Card.Text className="card-des">
                    +91 81082 22331 <br />
                    +91 99998 91715
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={6} md={4} lg={4}>
              <Card className="product-card">
                <div className="img-section">
                  <img src={mail} alt="Mail" />
                </div>
                <Card.Body className="card-body">
                  <Card.Title className="card-title">Mail Us</Card.Title>
                  <Card.Text className="card-des text-center">
                    info@mentationtech.in &nbsp; singhvishal2977@gmail.com
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* Forms */}
        <div className="container mt-5">
          <form className="form">
            <div className="mb-3 ">
              <input
                type="text"
                className="form-control input"
                id="firstName"
                placeholder="First Name"
              />
            </div>
            <div className="mb-3 ">
              <input
                type="text"
                className="form-control input"
                id="lastName"
                placeholder="Last Name"
              />
            </div>
            <div className="mb-3 ">
              <input
                type="email"
                className="form-control input"
                id="email"
                placeholder="Email"
              />
            </div>
            <div className="mb-3 ">
              <textarea
                className="form-control textarea"
                id="message"
                placeholder="Your Message"
                style={{ height: "100px" }}
              ></textarea>
            </div>
            <button className="button my-3">
              <span>Submit</span>
              <svg
                width="34"
                height="34"
                viewBox="0 0 74 74"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="37"
                  cy="37"
                  r="35.5"
                  stroke="black"
                  stroke-width="3"
                ></circle>
                <path
                  d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z"
                  fill="black"
                ></path>
              </svg>
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
