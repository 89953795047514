// src/Components/Home.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Slider.scss"; // For custom styling
import Banner from "../../assests/slider/video-1.mp4";

export default function Slider() {
  return (
    <div className="video-container">
      <video className="video-bg" src={Banner} autoPlay muted loop />
      {/* <img
        src={Banner}
        alt=""
        className="video-bg"
      /> */}
      <div className="overlay"></div>
      <div className="caption">
        <h3>
          <span className="span">
            Mentation <br />
            Technologies
          </span>
          <p>
            Empowering Tomorrow with Breakthrough Technologies Innovative
            Solutions for Vehicle Tracking, Finance, And Comprehensive Identity
            Management
          </p>
        </h3>
      </div>
      <p className="caption-text">
        Transforming Technology Across Industries Advanced Vehicle Tracking,
        Fintech Innovations, And Unmatched Security Solutions
      </p>
    </div>
  );
}
