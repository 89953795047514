import React from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "./Login.scss"

const Register = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Row className="w-100  login-main-container ">
        <Col xs={12} md={8} lg={6} className="mx-auto">
          <h3 className=" mb-4 login-heading">Register</h3>
          <Form>
            <Form.Group controlId="formUsername" className="mb-3">
              <Form.Label className='login-lable' >Username</Form.Label>
              <Form.Control className='input' type="text" placeholder="Enter username" />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label className='login-lable' >Email address</Form.Label>
              <Form.Control className='input' type="email" placeholder="Enter email" />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label className='login-lable' >Password</Form.Label>
              <Form.Control className='input' type="password" placeholder="Enter password" />
            </Form.Group>

            <Form.Group controlId="formConfirmPassword" className="mb-3">
              <Form.Label className='login-lable' >Confirm Password</Form.Label>
              <Form.Control className='input' type="password" placeholder="Confirm password" />
            </Form.Group>

            <button variant="primary" type="submit" className=" login-btn w-100 mb-3">
              Register
            </button>
            <div className="text-center sub-link ">
              <span>Already registered &nbsp;?&nbsp; </span>
              <Link to="/login" className='login-links' >Sign in</Link>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
