import React from "react";
import "./Management.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaMeta, FaXTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import nitinProfilePic from "../../../assests/management/Nitin1.jpg";
// import anandProfilePic from "../../../assests/management/anand1.jpeg";
import anandProfilePic from "../../../assests/management/anand1.png";

export default function Management() {
  return (
    <>
      <div className="management-container">
        <div className="banner">
          <div className="overlay">
            <Container>
              <Row className="banner-content">
                <Col xs={12} md={8} lg={6}>
                  <h1 className="banner-title">
                    Steering Vision and Excellence In Every Endeavor
                  </h1>
                  <p className="banner-description">
                    At Mentation Management, we navigate the path to
                    success with precision, guiding vision and excellence
                    in every endeavor. Let us chart your course towards
                    unparalleled achievements
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Container className="mt-2 management-team-heading">
          <h2 className="text-start">Our Management</h2>
          <p className="text-start">
            Meet the leadership team driving our company forward.
          </p>
        </Container>

        <Container className="my-5">
          <Row>
            <Col xs={12} md={7} lg={7} xl={8} className="management-data bg1">
              <div className="">
                <h1 className="management-title">
                  Nitin Navneet{" "}
                  <span className="management-subtitle">Co Founder & CEO</span>
                </h1>
                <p className="management-text">
                  Nitin Navneet boasts over two decades of experience in sales
                  and technology within the IT services industry. He has played
                  a pivotal role at Mentation, leading efforts to create
                  award-winning products and services across various market
                  segments, including banking, imaging, mobile technologies,
                  education, and financial inclusion.
                </p>
                <p className="management-text">
                  Nitin's professional stint with companies specializing in
                  RFID, biometrics, and printing solutions has been instrumental
                  in maintaining Mentation's competitive edge in product quality
                  and manufacturing processes. His extensive career includes
                  significant associations with multiple technology companies,
                  where he has leveraged his expertise to drive innovation and
                  growth. As a co-founder of several organizations in the
                  technology sector, he has demonstrated a strong
                  entrepreneurial spirit and a commitment to advancing the
                  industry
                </p>
                <div className="management-social-icons">
                  <div className="twitter">
                    <FaXTwitter className="management-icons" />
                  </div>
                  <div className="linkedin">
                    <FaLinkedinIn className="management-icons" />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={2} lg={4} xl={2} style={{ height: "100%" }}>
              <Card className="management-card mt-5">
                <Card.Img
                  src={nitinProfilePic}
                  alt="management team member"
                  className="management-img"
                />
              </Card>
            </Col>
          </Row>
        </Container>

        <Container className="my-5">
          <Row>
            <Col xs={12} md={7} lg={7} xl={8} className="management-data bg3 ">
              <div className="">
                <h1 className="management-title">
                  Anand Murari{" "}
                  <span className="management-subtitle">Co Founder & CMO</span>
                </h1>
                {/* <p className="management-subtitle"></p> */}
                <p className="management-text">
                  Anand Murari brings nearly two decades of experience across
                  Banking, Financial Services, Insurance, Franchisee expansions,
                  and IT sales. Throughout his career, Anand has been
                  consistently recognized and awarded for his exceptional team
                  management, innovative sales initiatives, and outstanding
                  relationship management
                </p>
                <p className="management-text">
                  Anand's unique blend of aggressiveness and innovation has
                  enabled him to establish new channels and revitalize
                  underperforming products. His ability to navigate and leverage
                  market dynamics, especially over the past decade, has allowed
                  him to continuously explore and capitalize on emerging market
                  opportunities
                </p>
                <div className="management-social-icons">
                  {/* <div className="meta">
                    <FaMeta className="management-icons" />
                  </div>
                  <div className="insta">
                    <FaInstagram className="management-icons" />
                  </div> */}
                  <div className="twitter">
                    <FaXTwitter className="management-icons" />
                  </div>
                  <div className="linkedin">
                    <FaLinkedinIn className="management-icons" />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={2} lg={4} xl={2}>
              <Card className="management-card mt-5">
                <Card.Img
                  variant="top"
                  src={anandProfilePic}
                  alt="management team member"
                  className="management-img"
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
