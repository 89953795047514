import React from "react";
import "./Fintech.scss";
import { Accordion, Card, Col, Container, Row } from "react-bootstrap";
import fintech from "../../../../data.json";
import dataSheet from "../../../../assests/Datasheet/MT580P Data Sheet.jpg";
import { Link } from "react-router-dom";
export default function Fintech() {
  //startes
  const fintechData = fintech.fintech;

  return (
    <>
      <div className="fintech-container">
        <div className="banner-container">
          <div className="banner">
            <div className="overlay"></div>
            <div className="text-content">
              <h1 className="heading-text">
                Innovative Solutions for Modern Financial Needs
              </h1>
              <p className="paragraph-text">
                This is some introductory text about the website. Feel free to
                explore and learn more about what we have to offer.
              </p>
            </div>
          </div>
        </div>

        <div className="second-container">
          <Container>
            <div className="right">
              <div className="title">
                <h1>The Fintech World</h1>
              </div>
              <div className="description">
                <p className="desc">
                  Financial Technology or FINTECH companies specializing in
                  payment solutions have transformed the way businesses and
                  consumers manage and process transactions. These companies
                  leverage advanced technology to offer innovative, efficient,
                  and secure payment methods that cater to the modern economy's
                  needs.
                </p>
              </div>
            </div>
          </Container>
        </div>
        {/* Accordian Colntaienr */}
        <Container>
          <div className="content-container">
            <div className="content-1">
              <div className="title">
                <h1>Impact on the Industry</h1>
              </div>
              <div className="des">
                These fintech companies have made payments faster, more
                convenient, and more secure. They have driven the shift towards
                digital and mobile payments, reduced reliance on cash, and
                opened up global markets by enabling cross-border transactions
                with ease. By offering innovative solutions, these companies
                have not only enhanced the customer experience but also helped
                businesses grow by simplifying payment processes and reducing
                costs associated with traditional banking systems.
              </div>
            </div>
            <div className="content-2">
              <div className="title">
                <h1>Mentation and FINTECH</h1>
              </div>
              <div className="des">
                One of the key aspect of the overall financial technology is
                that it is mandatory to have transaction record for all the
                payments made or transfers received. This is where the leading
                fintech players have made the maximum use of Mentation Bluetooth
                thermal printers. We are more than proud to be associated with
                industry stalwarts like Airtel Payments Bank, Fino Payments
                Bank, Religare Broking, Relipay, Ezeepay, Paynearby, Spice Money
                , Roinet ..... <br /> There is along list of regional players
                also who are using and promoting Mentation printers at their own
                level.
              </div>
            </div>
          </div>
        </Container>

        <div className="third-container  my-5">
          <div className="products-section">
            <h1 className="title mt-5">
              "Presenting Our Finest Explore{" "}
              <span>"Mentation Technologies"</span> Groundbreaking Fintech
              Products!"
            </h1>
            <Container>
              <Row className="g-4 card-gallery">
                {fintechData.map((product) => (
                  <Col xs={12} sm={12} md={6} lg={4} key={product.id}>
                    <Card className="card">
                      <div className="image-container">
                        <Card.Img
                          className="image"
                          variant="top"
                          src={product.productImage}
                        />
                      </div>
                      <Card.Body className="card-body">
                        <Card.Title className="card-title">
                          {product.productName}
                        </Card.Title>
                        <Card.Text className="card-text">
                          {product.productDescription}
                        </Card.Text>
                      </Card.Body>
                      <div className="button my-2">
                        <Link className="Link" to={product.baseUrl}>
                          Know More
                        </Link>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
 