import React from "react";
import "./Drivers.scss";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import mt580 from "../../../assests/Drivers/MT580P-USB-turn-to-serial-DriverInstaller_v1.10.0.exe_.zip";
import myPrinter from "../../../assests/Drivers/MyPrinter.zip";
import mt580p from "../../../assests/Drivers/MT580P-Windows-Driver.exe_.zip";
import guide from "../../../assests/Drivers/MT580P-Bluetooth-Interface-Installation-Windows.pdf";
import android from "../../../assests/Drivers/Android-Sample-Code.bIeX5jf5.zip.part";

const products = [
  {
    name: "MT580P USB to Serial Driver",
    file: mt580,
  },
  {
    name: "MyPrinter APK",
    file: myPrinter,
  },
  {
    name: "MT580P Windows Driver",
    file: mt580p,
  },
  {
    name: "Bluetooth Configuration guide",
    file: guide,
  },
  {
    name: "Mentation Printer Application",
    file: "https://play.google.com/store/apps/details?id=com.mentation.mentationprinter",
  },
  {
    name: "Android sample Code",
    file: android,
  },
];

export default function Drivers() {
  return (
    <>
      <div className="drivers-container">
        <div className="text-container">
          <h1 className="text-center mt-5">MT580P Bluetooth Thermal Printer Product Support</h1>
        </div>
        <Container className="container">
          <h1 className="my-4 text-center">Download Our Drivers</h1>
          <Row className="justify-content-center">
            {products.map((product, index) => (
              <Col xs={12} md={6} lg={4} key={index} className="mb-4">
                <Card>
                  <Card.Body className="d-flex justify-content-between align-items-center">
                    <Card.Title>{product.name}</Card.Title>
                    <Button
                      href={product.file}
                      download
                      variant="success"
                      className="d-flex align-items-center button"
                    >
                      <FaDownload className="me-2" />
                      Download
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}
