import { React } from "react";
import "./Company.scss";
import { Container, Row, Col } from "react-bootstrap";
import img1 from "../../../assests/Dropdowns/Certificate-1.png";
import img2 from "../../../assests/Dropdowns/Certificate-2.png";
import img3 from "../../../assests/Dropdowns/Certificate-3.png";
import img4 from "../../../assests/Dropdowns/Certificate-4.png";
import marquee1 from "../../../assests/Achivements/img1.png";
import marquee2 from "../../../assests/Achivements/img22.png";
import marquee3 from "../../../assests/Achivements/img3.png";
import marquee4 from "../../../assests/Achivements/img4.png";
import marquee5 from "../../../assests/Achivements/img5.png";
import marquee6 from "../../../assests/Achivements/img6.png";
import marquee7 from "../../../assests/Achivements/img7.png";
import marquee8 from "../../../assests/Achivements/img8.png";
import marquee9 from "../../../assests/Achivements/img9.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Marquee from "react-fast-marquee";

export default function Company() {
  return (
    <>
      <div className="company-container">
        <div className="banner">
          <div className="overlay">
            <Container>
              <Row className="banner-content">
                <Col xs={12} md={8} lg={6}>
                  <h1 className="banner-title">
                    Bridging the Gap Between Vision and Reality
                  </h1>
                  <p className="banner-description">
                    At Mentation Technologies , we leverage the latest
                    technologies to bring your business visions to life. Our
                    expert team transforms challenges into opportunities,
                    creating innovative solutions that propel your business
                    forward
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Container className="my-1">
          <Row className="about-company">
            <Col
              xs={12}
              md={12}
              lg={4}
              xl={12}
              className="d-flex flex-column justify-content-center mb-md-0"
            >
              <p className="title">Mentation Technologies Private Limited</p>
              <div className="description mt-3">
                <p className="content">
                  where innovation meets excellence, and every journey begins
                  with a bold step towards a brighter future. Our commitment to
                  pushing boundaries and fostering creativity ensures that
                  together, we pave new paths, conquer challenges, and achieve
                  remarkable milestones. Join us on this exciting adventure as
                  we shape tomorrow's possibilities, one innovative solution at
                  a time.
                </p>
              </div>
            </Col>

            <Col xs={12} md={12} lg={6} xl={12}>
              <div className="rightside-content">
                <div className="productimages">
                  <div className="image-container">
                    <img src={img1} alt="" className="img1" />
                    <div className="overlay-text">Overlay Text 1</div>
                  </div>
                  <div className="image-container">
                    <img src={img2} alt="" className="img1" />
                    <div className="overlay-text">Overlay Text 2</div>
                  </div>
                  <div className="image-container">
                    <img src={img3} alt="" className="img1" />
                    <div className="overlay-text">Overlay Text 3</div>
                  </div>
                  <div className="image-container">
                    <img src={img4} alt="" className="img1" />
                    <div className="overlay-text">Overlay Text 4</div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        {/* Profile Section */}
        <Container className="company-profile-container">
          <Row className="align-items-center">
            <Col xs={12} md={12} xl={12} lg={12} className="text-container">
              <h1 className="profile-heading">
                Mentation Technologies Innovating the Future of Hardware
                Solutions
              </h1>
              <p className="profile-paragraph">
                Mentation Technologies is a leading company at the forefront of
                hardware innovation, dedicated to creating cutting-edge devices
                that shape the future. Our product range includes advanced
                printers, smartwatches, and a variety of other state-of-the-art
                gadgets designed to lead the world in technological excellence.
                Each product we develop is a testament to our commitment to
                quality, innovation, and customer satisfaction
              </p>
              <p className="profile-paragraph">
                Our printers are engineered with precision to deliver
                unparalleled performance, whether for home, office, or
                industrial use. They feature the latest in printing Technologies
                , ensuring high-quality output, efficiency, and reliability.
                From high-speed laser printers to versatile inkjet models,
                Mentation Technologies ’s printers are designed to meet the
                diverse needs of our customers, making printing tasks seamless
                and hassle-free
              </p>
              <p className="profile-paragraph">
                In the wearable Technologies sector, our smartwatches stand out
                for their sleek design and advanced functionality. They are
                equipped with the latest sensors and connectivity options,
                allowing users to stay connected, monitor their health, and
                enhance their productivity on the go. Our smartwatches are not
                just devices; they are companions that integrate seamlessly into
                the modern lifestyle, offering a blend of style and substance
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="partners-container">
          <div className="title">
            <h2>
              Successful Collaborations with Industry Leaders Enhancing
              Innovation and Growth
            </h2>
            <p className="desc my-5">
              We are excited to announce our strategic collaborations with
              leading industry partners to drive innovation and excellence
              across our projects.
            </p>
          </div>
          {/* First Marquee */}
          <Marquee
            autoFill
            pauseOnClick
            direction="left"
            speed={100}
            loop={0}
            className="marquee-Container"
          >
            <div className="marquee">
              <div className="image-box">
                <img src={marquee1} alt="Marquee 1" className="images" />
              </div>
            </div>
            <div className="marquee">
              <div className="image-box">
                <img src={marquee9} alt="Marquee 2" className="images" />
              </div>
            </div>
            <div className="marquee">
              <div className="image-box">
                <img src={marquee5} alt="Marquee 3" className="images" />
              </div>
            </div>
            <div className="marquee">
              <div className="image-box">
                <img src={marquee4} alt="Marquee 4" className="images" />
              </div>
            </div>
            <div className="marquee">
              <div className="image-box">
                <img src={marquee7} alt="Marquee 5" className="images" />
              </div>
            </div>
            <div className="marquee">
              <div className="image-box">
                <img src={marquee2} alt="Marquee 6" className="images" />
              </div>
            </div>
            <div className="marquee">
              <div className="image-box">
                <img src={marquee3} alt="Marquee 7" className="images" />
              </div>
            </div>
            <div className="marquee">
              <div className="image-box">
                <img src={marquee8} alt="Marquee 8" className="images" />
              </div>
            </div>
            <div className="marquee">
              <div className="image-box">
                <img src={marquee6} alt="Marquee 9" className="images" />
              </div>
            </div>
          </Marquee>
        </Container>

        <Container className="swiper-main-container mt-5">
          <h1 className="swiper-heading mb-5">Our Achievements</h1>
          <Swiper
            loop={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
            }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide>
              <div className="swiper-card">
                <div className="image-container">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYAvFFVoobTSkhKPudACsT-mopgTN5Enbx1w&s"
                    alt="Slide 1"
                  />
                </div>
                <div className="overlay">
                  <div className="text">Bihar Transport Department</div>
                  <p className="description">
                    Registered with the State Transport Department of Bihar for
                    the supply and installation of AIS140 VLTD devices in
                    commercial vehicles.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-card">
                <div className="image-container">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIv2bcBKrbGxkjWvLX08z-80PgmL9v-SRvzA&s"
                    alt="Slide 2"
                  />
                </div>
                <div className="overlay">
                  <div className="text">Union Bank of India</div>
                  <p className="description">
                    Supplied more than 5000 devices to Union Bank of India, as
                    well as several other banks, both directly and through
                    distributors.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-card">
                <div className="image-container">
                  <img
                    src="https://rukminim2.flixcart.com/image/850/1000/jpbic280/biometric-device/n/3/n/tmf20-tatvik-original-imafbgbbarrhxevu.jpeg?q=90&crop=false"
                    alt="Slide 3"
                  />
                </div>
                <div className="overlay">
                  <div className="text">Aadhar Verification</div>
                  <p className="description">
                    Supplied biometric devices to the State Health Society Bihar
                    and the State Secretariat of Jammu & Kashmir.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-card">
                <div className="image-container">
                  <img
                    src="https://image.ntnews.com/wp-content/uploads/2024/05/Voter_V_jpg--442x260-4g.webp?sw=412&dsz=442x260&iw=400&p=false&r=2.625"
                    alt="Slide 4"
                  />
                </div>
                <div className="overlay">
                  <div className="text">Voter Slip Generation</div>
                  <p className="description">
                    More than 10000 Bluetooth Thermal Printers in circulation
                    which are used by political parties for voter slip
                    generation.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-card">
                <div className="image-container">
                  <img
                    src="https://5.imimg.com/data5/SELLER/Default/2023/7/329804871/GQ/HL/ZO/56165034/ais-140-gps-tracking-system.jpeg"
                    alt="Slide 5"
                  />
                </div>
                <div className="overlay">
                  <div className="text">15000 GPS Devices</div>
                  <p className="description">
                    More than 15000 GPS devices in circulation with secured
                    tracking application.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Container>
      </div>
    </>
  );
}
