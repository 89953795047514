import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../../styles/Product.scss";
import mt580p1 from "../../../../../assests/product/Fintech/MT580P-1.png";
import mt580p2 from "../../../../../assests/product/Fintech/MT580P-2.png";
import mt580p3 from "../../../../../assests/product/Fintech/MT580P-3.png";
import mt580p4 from "../../../../../assests/product/Fintech/MT580P-4.png";
import mt580pdatasheet from "../../../../../assests/product/Fintech/Datasheet/MT580P Data Sheet.jpg";

export default function Smartphone() {
  // Initialize state for the main image URL
  const [mainImage, setMainImage] = useState(mt580p1);

  // Array of image imports
  const images = [mt580p1,mt580p2, mt580p3, mt580p4];

  // Handle thumbnail click
  const handleThumbnailClick = (img) => {
    setMainImage(img);
  };

  return (
    <div>
      <div className="single-product">
        <section className="py-5">
          <div className="container">
            <div className="row gx-5">
              <aside className="col-lg-6 aside">
                <div className="image-container">
                  <img
                    className="rounded-4 fit images"
                    src={mainImage}
                    alt="Main view"
                  />
                </div>
                <div className="d-flex justify-content-center mb-3">
                  {images.map((img, index) => (
                    <Link
                      key={index}
                      className="border mx-1 rounded-2 item-thumb"
                      onClick={() => handleThumbnailClick(img)}
                      to="#"
                    >
                      <img
                        width="60"
                        height="60"
                        className="rounded-2 thumbnail"
                        src={img}
                        alt={`Thumbnail ${index + 1}`}
                      />
                    </Link>
                  ))}
                </div>
              </aside>
              <main className="col-lg-6 main">
                <div className="ps-lg-3">
                  <h4 className="title">
                   Mentation Bluetooth Thermal Printer 58mm
                  </h4>
                  <div className="d-flex flex-row my-3 product-name">
                    <p>MT580P</p>
                  </div>
                  <div className="description">
                    <p>
                      The Mentation MT580P is a portable Bluetooth Thermal
                      Printer with 58mm paper size. This is popular and most
                      accepted printer in the widespread network of Fintech
                      agents, Bank BC's and authorized service centers It's
                      designed to be compact and lightweight, making it suitable
                      for various on-the-go applications.
                    </p>
                  </div>

                  <div className="key-features">
                    <h1>Key features typically include</h1>
                    <div className="list">
                      <ul className="features">
                        <li>
                          <strong>Bluetooth connectivity:</strong> <br /> For
                          easy pairing with smartphones, tablets, and computers.
                        </li>
                        <li>
                          <strong>High printing speed:</strong> <br />
                          Efficiently produces receipts, labels, and other
                          documents.
                        </li>
                        <li>
                          <strong>Rechargeable battery:</strong> <br /> Provides
                          extended usage without needing constant power supply.
                        </li>
                        <li>
                          <strong>Compact and lightweight design:</strong>
                          <br /> Ideal for portability.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row short-heighlight">
                    <p className="des">Get To know More about this Product</p>
                    <span className="mt-2">
                      <Link
                        to={mt580pdatasheet}
                        target="_blank"
                        download="MT580P.pdf"
                        className="link"
                      >
                        Download PDF
                      </Link>
                    </span>
                  </div>
                  <hr />
                </div>
              </main>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
