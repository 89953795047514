import React, { useState, useEffect, useRef } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import Logo from "../../assests/Home/Logo.png";

function CustomNavbar() {
  const [expanded, setExpanded] = useState(false);
  const [showBrandText, setShowBrandText] = useState(true);
  const navbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    const handleResize = () => {
      setShowBrandText(window.innerWidth > 992); // Adjust based on breakpoint
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("resize", handleResize);
    handleResize(); // Call once to set initial state

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", handleResize);
    };
  }, [navbarRef]);

  return (
    <Navbar
      ref={navbarRef}
      expand="lg"
      className={`mainContainer ${expanded ? "expanded" : ""}`}
      expanded={expanded}
    >
      <Container>
        {/* Wrap the logo and toggle button */}
        <div className="navbar-header d-flex w-100 justify-content-between align-items-center">
          <Navbar.Brand
            as={Link}
            className="brand"
            to="/"
            onClick={() => setExpanded(false)}
          >
            <img src={Logo} alt="Logo" className="logo" />
            {showBrandText && (
              <span className="brand-text">Mentation Technologies</span>
            )}
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="navbar-nav"
            onClick={() => setExpanded(!expanded)}
            className="toggle-btn"
          />
        </div>

        <Navbar.Collapse id="navbar-nav" className="justify-content-center">
          <Nav className="mx-auto">
            <Nav.Link
              as={Link}
              className="links"
              to="/"
              onClick={() => setExpanded(false)}
            >
              Home
            </Nav.Link>

            <NavDropdown title="About" className="links" id="about-dropdown">
              <NavDropdown.Item
                as={Link}
                className="links"
                to="/company"
                onClick={() => setExpanded(false)}
              >
                Company
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                className="links"
                to="/management"
                onClick={() => setExpanded(false)}
              >
                Management
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title="Products"
              className="links"
              id="products-dropdown"
            >
              <NavDropdown
                title="Printing Solution"
                className="dropend"
                id="fintech-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="products/fintech"
                  onClick={() => setExpanded(false)}
                >
                  Fintech Solution
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="products/Billing"
                  onClick={() => setExpanded(false)}
                >
                  Billing & POS Solution
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="products/Voter"
                  onClick={() => setExpanded(false)}
                >
                  Voter Management
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Transport Solutions"
                className="dropend"
                id="vehicle-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="products/AIS140"
                  onClick={() => setExpanded(false)}
                >
                  AIS 140 VLTD
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="products/FleetManagement"
                  onClick={() => setExpanded(false)}
                >
                  Fleet Management
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Identity Management"
                className="dropend"
                id="identity-dropdown"
              >
                <NavDropdown.Item
                  as={Link}
                  to="products/facial"
                  onClick={() => setExpanded(false)}
                >
                  Facial Recognition
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="products/Biometric"
                  onClick={() => setExpanded(false)}
                >
                  Biometric Solutions
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="products/attendence"
                  onClick={() => setExpanded(false)}
                  className="disabled"
                >
                  Attendance Management
                </NavDropdown.Item>
              </NavDropdown>
            </NavDropdown>

            <NavDropdown
              title="Support"
              className="links"
              id="support-dropdown"
            >
              <NavDropdown.Item
                as={Link}
                className="links"
                to="/contact"
                onClick={() => setExpanded(false)}
              >
                Contact Us
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                className="links"
                to="/drivers"
                onClick={() => setExpanded(false)}
              >
                Mt Download
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                className="links"
                to="/blog"
                onClick={() => setExpanded(false)}
              >
                Product Support
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CustomNavbar;
