import { FaMeta } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import React from "react";
import { Link } from "react-router-dom";
import "./Error.scss";

export default function Error() {
  return (
    <>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>Oops! Nothing was found</h2>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable
            <Link to="/">Return to homepage</Link>
          </p>
          <div className="notfound-social">
            <Link to="#">
              <FaMeta />
            </Link>
            <Link to="#">
              <FaXTwitter />
            </Link>
            <Link to="#">
              <FaInstagram />
            </Link>
            <Link to="#">
              <FaLinkedinIn />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
