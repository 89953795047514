// src/Login.js
import React from "react";
import "./Login.scss";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Row className="w-100  login-main-container">
        <Col xs={12} md={8} lg={6} className="mx-auto">
          <h3 className="text-center mb-4 login-heading ">Login</h3>
          <Form>
            <Form.Group controlId="formUsername" className="mb-3">
              <Form.Label className="login-lable" >Username</Form.Label>
              <Form.Control className="input"  type="text" placeholder="Enter username" />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label className="login-lable" >Email address</Form.Label>
              <Form.Control className="input"  type="email" placeholder="Enter email" />
            </Form.Group>

            <Form.Group controlId="formPassword" className="mb-3">
              <Form.Label className="login-lable" >Password</Form.Label>
              <Form.Control className="input"  type="password" placeholder="Password" />
            </Form.Group>

            <div className="d-flex justify-content-center">
              <button
                variant="primary"
                type="submit"
                className="login-btn  mb-3"
              >
                Login
              </button>
            </div>
            <div className="text-center">
              <span className="sub-link" >Not registered yet &nbsp;?&nbsp; </span>
              <Link to="/register" className="login-links">Register</Link>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
