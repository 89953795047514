import React, { useState } from "react";
import "../../../styles/Product.scss";
import mt580dp3 from "../../../../../assests/product/Fintech/MT580DP-3.png";
import mt580dp4 from "../../../../../assests/product/Fintech/MT580DP-4.png";
import mt580dp5 from "../../../../../assests/product/Fintech/MT580DP-5.png";
import mt580dp6 from "../../../../../assests/product/Fintech/MT580DP-6.png";

import mt580Dpdatasheet from "../../../../../assests/product/Fintech/Datasheet/MT580DP-Data-sheet.jpeg";
import { Link } from "react-router-dom";

export default function Smarttv() {
  // Initialize state for the main image URL
  const [mainImage, setMainImage] = useState(mt580dp3);

  // Array of image imports
  const images = [ mt580dp3, mt580dp4, mt580dp5, mt580dp6];

  // Handle thumbnail click
  const handleThumbnailClick = (img) => {
    setMainImage(img);
  };

  return (
    <div>
      <div className="single-product">
        <section className="py-5">
          <div className="container">
            <div className="row gx-5">
              <aside className="col-lg-6 aside">
                <div className="image-container">
                  <img
                    className="rounded-4 fit images"
                    src={mainImage}
                    alt="Main view"
                  />
                </div>
                <div className="d-flex justify-content-center mb-3">
                  {images.map((img, index) => (
                    <Link
                      key={index}
                      className="border mx-1 rounded-2 item-thumb"
                      onClick={() => handleThumbnailClick(img)}
                      to="#"
                    >
                      <img
                        width="60"
                        height="60"
                        className="rounded-2 thumbnail"
                        src={img}
                        alt={`Thumbnail ${index + 1}`}
                      />
                    </Link>
                  ))}
                </div>
              </aside>
              <main className="col-lg-6 main">
                <div className="ps-lg-3">
                  <h4 className="title text-dark">
                  Mentation Line thermal printer in Desktop
                  category
                  </h4>
                  <div className="d-flex flex-row my-3 product-name">
                    <p>MT580DP</p>
                  </div>

                  <div className="description">
                    <p>
                      Mentation MT580DP is a line thermal printer in Desktop
                      category. It is a proper value addition in the 58mm
                      category with no requirement of battery for static uses.
                      This provides an edge to the same segment of Fintech
                      solution providers who doesn't require a printer for
                      mobility
                    </p>
                  </div>

                  <div className="key-features">
                    <h1>Key features typically include</h1>
                    <div className="list">
                      <ul className="features">
                        <li>
                          <strong>Bluetooth connectivity:</strong> <br /> For
                          easy pairing with smartphones, tablets, and computers.
                        </li>
                        <li>
                          <strong>High printing speed:</strong> <br />
                          Efficiently produces receipts, labels, and other
                          documents.
                        </li>
                        <li>
                          <strong>Printers:</strong> <br /> 
                          Operate on electricity for dependable and consistent printing.
                        </li>
                        <li>
                          <strong>Compact and lightweight design:</strong>
                          <br /> Ideal for portability.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row short-heighlight">
                    <p className="des">Get To know More about this Product</p>
                    <span>
                      <Link
                        to={mt580Dpdatasheet}
                        target="_blank"
                        download="MT580DP.pdf"
                        className="link"
                      >
                        Download PDF
                      </Link>
                    </span>
                  </div>
                  <hr />
                </div>
              </main>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
