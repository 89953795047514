import React from "react";
import "./Attendence.scss";
import { Accordion, Card, Container } from "react-bootstrap";
import fintech from "../../../../data.json";

export default function Attendence() {
  const fintechData = fintech.fintech;

  return (
    <>
      <div className="attendence-container">
        <div className="attendence-banner-container">
          <div className="attendence-banner">
            <div className="overlay"></div>
            <div className="text-content">
              <h1 className="heading-text">
                Next-Generation attendence Management Secure, Simple, and
                Scalable
              </h1>
              <p className="paragraph-text">
                Streamline your digital security with our advanced attendence
                management solutions. Enjoy seamless control and protection for
                your personal and organizational data, ensuring optimal security
                and efficiency.
              </p>
            </div>
          </div>
        </div>

        <div className="second-container">
          <Container>
            <div className="right">
              <div className="title">
                <h1>What's attendence management system Means</h1>
              </div>
              <div className="description">
                <p className="desc">
                  <span>"Attendance management"</span> tracks and records
                  employee attendance, working hours, and absences. It automates
                  clocking in and out, leave requests, and overtime, enhancing
                  accuracy and reducing administrative workload
                </p>
              </div>
            </div>
          </Container>
        </div>
        {/* Accordian Container */}
        <Container>
          <div className="accordian-container">
            <Container>
              <strong className="span">Features</strong>
              <Accordion defaultActiveKey="100" className="custom-accordion">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="accordion-header">
                    Time Tracking
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Records employee clock-in and clock-out times.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="accordion-header">
                    Leave Management
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Handles leave requests, approvals, and tracking.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="accordion-header">
                    Attendance Reporting
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Generates detailed reports on attendance patterns and
                    absences.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="accordion-header">
                    Automated Alerts
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Sends notifications for missed punches or upcoming leaves.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="accordion-header">
                    Integration
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Connects with payroll and HR systems for seamless data
                    management.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header className="accordion-header">
                    Biometric Integration
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Uses biometric data (e.g., fingerprints) for accurate time
                    tracking.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header className="accordion-header">
                    Remote Access
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Allows employees to check in/out and manage attendance
                    remotely.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header className="accordion-header">
                    Shift Management
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Manages and schedules shifts and work hours.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
            <div className="left">
              <h1 className="heading">Mentation Technologies</h1>
              <div className="top">
                <h3 className="title">Motive for IMS Revolution</h3>
                <p className="desc">
                  The motive for revolutionizing attendance management is to
                  enhance accuracy and efficiency in tracking employee hours and
                  absences. By leveraging advanced technology, such as biometric
                  systems and automated reporting, the goal is to streamline
                  administrative processes, reduce errors, and improve overall
                  workforce management
                </p>
              </div>
              <div className="top">
                <h3 className="title">Aim of the Company</h3>
                <p className="desc">
                  The aim of Mentation Technologies regarding attendance
                  management is to provide advanced solutions that streamline
                  and automate tracking of employee hours and absences. We focus
                  on enhancing accuracy, reducing administrative overhead, and
                  improving overall efficiency in workforce management through
                  innovative technology.
                </p>
              </div>
            </div>
          </div>
        </Container>

        <div className="third-container">
          <div className="top">
            <Container>
              <div className="content">
                <h1 className="title">
                  <span>"Mentation Technologies"</span>Latest Innovations
                </h1>
                <p className="desc">
                  Mentation Technologies is leading the charge in attendance
                  management with our latest innovations. Explore our
                  cutting-edge solutions designed to enhance accuracy and
                  streamline tracking, ensuring efficient and precise management
                  of employee attendance
                </p>
              </div>
            </Container>
          </div>
          <div className="products-section">
            <h1 className="title">
              "Presenting Our Finest Explore{" "}
              <span>"Mentation Technologies"</span> Groundbreaking 
              Products!"
            </h1>
            <Container>
              <div className="card-gallery">
                {fintechData.map((product) => {
                  return (
                    <>
                      <Card key={product.id} className="card">
                        <div className="image-container">
                          <Card.Img
                            className="image"
                            variant="top"
                            src={product.productImage}
                          />
                        </div>
                        <Card.Body className="card-body">
                          <Card.Title className="card-title">
                            {product.productName}
                          </Card.Title>
                          <Card.Text className="card-text">
                            {product.productDescription}
                          </Card.Text>
                        </Card.Body>
                        <div className="button">
                          <button to={`/product/${product.id}`} className="">
                            Know More
                          </button>
                        </div>
                      </Card>
                    </>
                  );
                })}
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
