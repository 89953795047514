import React from "react";
import "./Biometric.scss";
import { Accordion, Card, Container } from "react-bootstrap";
import fintech from "../../../../data.json";

export default function Biometric() {
  const fintechData = fintech.fintech;

  return (
    <>
      <div className="biometric-container">
        <div className="biometric-banner-container">
          <div className="biometric-banner">
            <div className="overlay"></div>
            <div className="text-content">
              <h1 className="heading-text">
                Next-Generation biometric Management Secure, Simple, and
                Scalable
              </h1>
              <p className="paragraph-text">
                Streamline your digital security with our advanced biometric
                management solutions. Enjoy seamless control and protection for
                your personal and organizational data, ensuring optimal security
                and efficiency.
              </p>
            </div>
          </div>
        </div>

        <div className="second-container">
          <Container>
            <div className="right">
              <div className="title">
                {/* <h1>What's biometric management system Means</h1> */}
              </div>
              <div className="description">
                <p className="desc">
                  A biometric management system uses unique biological traits,
                  like fingerprints or facial recognition, to securely verify
                  and manage user identities. It enhances security by ensuring
                  only authorized individuals gain access
                </p>
              </div>
            </div>
          </Container>
        </div>
        {/* Accordian Container */}
        <Container>
          <div className="accordian-container">
            <Container>
              <strong className="span">Features</strong>
              <Accordion defaultActiveKey="100" className="custom-accordion">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="accordion-header">
                    Biometric Authentication
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Uses unique traits (e.g., fingerprints, facial recognition)
                    for secure user verification.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="accordion-header">
                    Multi-Biometric Support
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Supports various biometric methods for flexibility.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header className="accordion-header">
                    Real-Time Matching
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Provides instant comparison of biometric data with stored
                    records.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header className="accordion-header">
                    Secure Data Storage
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Safeguards biometric information against unauthorized
                    access.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header className="accordion-header">
                    Access Control Integration
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Works with access systems to manage secure entry.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header className="accordion-header">
                    User Enrollment
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Captures and registers biometric data for new users.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header className="accordion-header">
                    Audit Trails
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Records biometric authentication activities for security and
                    compliance.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header className="accordion-header">
                    Self-Service
                  </Accordion.Header>
                  <Accordion.Body className="accordian-body">
                    Allows users to manage and update their biometric data.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
            <div className="left">
              <h1 className="heading">Mentation Technologies</h1>
              <div className="top">
                <h3 className="title">
                  Motive for biometric management Revolution
                </h3>
                <p className="desc">
                  The motive for revolutionizing biometric management is to
                  enhance security and streamline user identification processes
                  by leveraging advanced biometric technology. This innovation
                  aims to provide more accurate, reliable, and efficient methods
                  for verifying identities, reducing fraud, and simplifying
                  access control across various applications and systems.
                </p>
              </div>
              <div className="top">
                <h3 className="title">Aim of the Company</h3>
                <p className="desc">
                  The aim of Mentation Technologies regarding biometric
                  management is to deliver cutting-edge solutions that enhance
                  security and streamline identity verification processes. We
                  focus on providing accurate, reliable, and efficient biometric
                  technology to reduce fraud, improve access control, and
                  support seamless user experiences.
                </p>
              </div>
            </div>
          </div>
        </Container>

        <div className="third-container">
          <div className="top">
            <Container>
              <div className="content">
                <h1 className="title">
                  <span>"Mentation Technologies"</span>Latest Innovations
                </h1>
                <p className="desc">
                  Mentation Technologies is leading the charge in biometric
                  management with our latest IMS innovations. Explore our
                  cutting-edge solutions crafted to improve security and
                  streamline access, ensuring you manage identities with ease
                  and precision.
                </p>
              </div>
            </Container>
          </div>
          <div className="products-section">
            <h1 className="title">
              <span>"Mentation Technologies"</span> Groundbreaking Biometric Management 
              Products!"
            </h1>
            <Container>
              <div className="card-gallery">
                {fintechData.map((product) => {
                  return (
                    <>
                      <Card key={product.id} className="card">
                        <div className="image-container">
                          <Card.Img
                            className="image"
                            variant="top"
                            src={product.productImage}
                          />
                        </div>
                        <Card.Body className="card-body">
                          <Card.Title className="card-title">
                            {product.productName}
                          </Card.Title>
                          <Card.Text className="card-text">
                            {product.productDescription}
                          </Card.Text>
                        </Card.Body>
                        <div className="button">
                          <button to={`/product/${product.id}`} className="">
                            Know More
                          </button>
                        </div>
                      </Card>
                    </>
                  );
                })}
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
