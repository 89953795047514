import React from "react";
import "./identity.scss";
import {  Card, Col, Container, Row } from "react-bootstrap";
import fintech from "../../../../data.json";
import { Link } from "react-router-dom";

export default function IndentityManagement() {
  const fintechData = fintech.fintech;

  return (
    <>
      <div className="identity-container">
        <div className="identity-banner-container">
          <div className="identity-banner">
            <div className="overlay"></div>
            <div className="text-content">
              <h1 className="heading-text">
                Next Generation Identity Management Secure, Simple, and Scalable
              </h1>
              <p className="paragraph-text">
                Streamline your digital security with our advanced identity
                management solutions. Enjoy seamless control and protection for
                your personal and organizational data, ensuring optimal security
                and efficiency.
              </p>
            </div>
          </div>
        </div>

        <div className="second-container">
          <Container>
            <div className="right">
              <div className="title">
                <h1>Identity Management In India</h1>
              </div>
              <div className="description">
                <p className="desc">
                  Identity management is a critical component of India's digital
                  infrastructure, playing a significant role in governance,
                  financial inclusion, and public service delivery. The most
                  prominent identity management solution in India is Aadhaar, a
                  unique identification system introduced by the Government of
                  India in 2009 and managed by the Unique Identification
                  Authority of India (UIDAI). Despite its success, Aadhaar has
                  faced challenges, particularly concerning data privacy and
                  security. Concerns about the potential misuse of personal data
                  and unauthorized access have led to calls for robust data
                  protection regulations.
                </p>
              </div>
            </div>
          </Container>
        </div>
        {/* Accordian Container */}
        <Container>
          <div className="content-container">
            <div className="content-1">
              <div className="title">
                <h1>Future of Identity Management and Mentation</h1>
              </div>
              <div className="des">
                Looking at the immense potential across the country which is
                fuelled by the robust economic growth in India, Mentation has
                identified, Explored and created solution which goes beyond the
                routine solutions offered in the current market. The future of
                identity management in India lies in enhancing security,
                improving user accessibility, and expanding the use of digital
                identities across various sectors and that is where our focus
                area is. Innovations such as digital lockers, e-KYC (Know Your
                Customer), and the integration of blockchain technology could
                further strengthen identity management solutions in India,
                ensuring they are secure, inclusive, and user-friendly
              </div>
            </div>
            {/* <div className="content-2">
              <div className="title">
                <h1>Mentation and FINTECH</h1>
              </div>
              <div className="des">
                One of the key aspect of the overall financial technology is
                that it is mandatory to have transaction record for all the
                payments made or transfers received. This is where the leading
                fintech players have made the maximum use of Mentation Bluetooth
                thermal printers. We are more than proud to be associated with
                industry stalwarts like Airtel Payments Bank, Fino Payments
                Bank, Religare Broking, Relipay, Ezeepay, Paynearby, Spice Money
                , Roinet ..... <br /> There is along list of regional players
                also who are using and promoting Mentation printers at their own
                level.
              </div>
            </div> */}
          </div>
        </Container>

        <div className="third-container">
          <div className="top">
            {/* <Container>
              <div className="content">
                <h1 className="title">
                  <span>"Mentation Technologies"</span>Latest IMS Innovations
                </h1>
                <p className="desc">
                  Mentation Technologies is leading the charge in identity
                  management with our latest IMS innovations. Explore our
                  cutting-edge solutions crafted to improve security and
                  streamline access, ensuring you manage identities with ease
                  and precision.
                </p>
              </div>
            </Container> */}
          </div>
          <div className="third-container  my-5">
          <div className="products-section">
            <h1 className="title mt-5">
              "Presenting Our Finest Explore{" "}
              <span>"Mentation Technologies"</span> Groundbreaking Fintech
              Products!"
            </h1>
            <Container>
              <Row className="g-4 card-gallery">
                {fintechData.map((product) => (
                  <Col xs={12} sm={12} md={6} lg={4} key={product.id}>
                    <Card className="card">
                      <div className="image-container">
                        <Card.Img
                          className="image"
                          variant="top"
                          src={product.productImage}
                        />
                      </div>
                      <Card.Body className="card-body">
                        <Card.Title className="card-title">
                          {product.productName}
                        </Card.Title>
                        <Card.Text className="card-text">
                          {product.productDescription}
                        </Card.Text>
                      </Card.Body>
                      <div className="button my-2">
                        <Link className="Link" to={product.baseUrl}>
                          Know More
                        </Link>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}
