import React from "react";
import "./Fleet.scss";
import { Card, Col, Container, Row } from "react-bootstrap";
import fintech from "../../../../data.json";
import { Link } from "react-router-dom";

export default function Fleet() {
  const fintechData = fintech.fintech;

  return (
    <>
      <div className="fleet-container">
        <div className="banner-container">
          <div className="banner">
            <div className="overlay"></div>
            <div className="text-content">
              <h1 className="heading-text">
                Optimize Your Fleet with Advanced Management Solutions
              </h1>
              <p className="paragraph-text">
                Optimize Your Fleet with Advanced Solutions" provides real-time
                tracking and route optimization to boost efficiency and cut
                costs.
              </p>
            </div>
          </div>
        </div>

        <div className="second-container">
          <Container>
            <div className="right">
              <div className="title">
                <h1>Fleet Management</h1>
              </div>
              <div className="description">
                <p className="desc">
                  Fleet management is the administrative approach used to
                  organize and coordinate work vehicles with the aim of
                  improving efficiency, reducing costs, and providing compliance
                  with government regulations. It involves managing a fleet of
                  commercial motor vehicles, such as cars, vans, trucks, and
                  specialized vehicles, to ensure they are running efficiently
                  and effectively.
                </p>
              </div>
            </div>
          </Container>
        </div>

        <Container>
          <div className="content-container">
            <div className="content-1">
              <div className="title">
                <h1>The need of mandate for Fleet Management</h1>
              </div>
              <div className="des">
                A mandate for fleet management can be a powerful tool to ensure
                that businesses, especially those with large fleets, operate
                efficiently, safely, and in compliance with regulations. By
                imposing standardized practices and guidelines, a mandate can
                help address common challenges such as excessive fuel
                consumption, driver safety violations, and inadequate
                maintenance. This, in turn, can lead to significant cost
                savings, reduced environmental impact, and improved overall
                operational efficiency.
              </div>
            </div>
            <div className="content-2">
              <div className="title">
                <h1>Mentation and VLTD</h1>
              </div>
              <div className="des">
                Mentation offers cutting-edge software solutions and
                comprehensive services designed to optimize your fleet
                operations. Our innovative platform provides real-time tracking,
                route optimization, fuel management, driver behavior analysis,
                and much more. By leveraging Mentation's advanced technology,
                you can significantly reduce costs, improve efficiency, and
                enhance safety within your fleet. Our expert team is dedicated
                to delivering tailored solutions that meet your specific needs.
                From small businesses to large corporations, Mentation has the
                expertise to help you achieve your fleet management goals.
              </div>
            </div>
          </div>
        </Container>

        <div className="third-container  my-5">
          <div className="products-section">
            <h1 className="title mt-5">
              "Presenting Our Finest Explore{" "}
              <span>"Mentation Technologies"</span> Groundbreaking Fintech
              Products!"
            </h1>
            <Container>
              <Row className="g-4 card-gallery">
                {fintechData.map((product) => (
                  <Col xs={12} sm={12} md={6} lg={4} key={product.id}>
                    <Card className="card">
                      <div className="image-container">
                        <Card.Img
                          className="image"
                          variant="top"
                          src={product.productImage}
                        />
                      </div>
                      <Card.Body className="card-body">
                        <Card.Title className="card-title">
                          {product.productName}
                        </Card.Title>
                        <Card.Text className="card-text">
                          {product.productDescription}
                        </Card.Text>
                      </Card.Body>
                      <div className="button my-2">
                        <Link className="Link" to={product.baseUrl}>
                          Know More
                        </Link>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
