import React, { useState } from "react";
import "../../../styles/Product.scss";
import mt580dp3 from "../../../../../assests/product/Billing&POS/MT800P2-1.png";
import mt580dp4 from "../../../../../assests/product/Billing&POS/MT800P2-2.png";
import mt580dp5 from "../../../../../assests/product/Billing&POS/MT800P2-3.png";
// import mt580dp6 from "../../../../../assests/product/Billing&POS/MT800P2-1.png";

import mt580Dpdatasheet from "../../../../../assests/product/Billing&POS/Datasheet/MT800P2.pdf";
import { Link } from "react-router-dom";
export default function P2() {
  // Initialize state for the main image URL
  const [mainImage, setMainImage] = useState(mt580dp5);

  // Array of image imports
  const images = [mt580dp5, mt580dp4, mt580dp3];

  // Handle thumbnail click
  const handleThumbnailClick = (img) => {
    setMainImage(img);
  };
  return (
    <div>
      <div className="single-product">
        <section className="py-5">
          <div className="container">
            <div className="row gx-5">
              <aside className="col-lg-6 aside">
                <div className="image-container">
                  <img
                    className="rounded-4 fit images"
                    src={mainImage}
                    alt="Main view"
                  />
                </div>
                <div className="d-flex justify-content-center mb-3">
                  {images.map((img, index) => (
                    <Link
                      key={index}
                      className="border mx-1 rounded-2 item-thumb"
                      onClick={() => handleThumbnailClick(img)}
                      to="#"
                    >
                      <img
                        width="60"
                        height="60"
                        className="rounded-2 thumbnail"
                        src={img}
                        alt={`Thumbnail ${index + 1}`}
                      />
                    </Link>
                  ))}
                </div>
              </aside>
              <main className="col-lg-6 main">
                <div className="ps-lg-3">
                  <h4 className="title text-dark">
                    label printer compatible with 80mm paper
                  </h4>
                  <div className="d-flex flex-row my-3 product-name">
                    <p>MT800P2</p>
                  </div>

                  <div className="description">
                    <p>
                      The Mentation MT800P2 is a portable Bluetooth label
                      printer compatible with 80mm paper. It is highly favored
                      by Kirana store owners, packaged food suppliers,
                      electrical shops, ticketing service providers, and
                      authorized common service center
                    </p>
                  </div>

                  <div className="key-features">
                    <h1>Key features typically include</h1>
                    <div className="list">
                      <ul className="features">
                        <li>
                          <strong>Bluetooth connectivity:</strong> <br /> For
                          easy pairing with smartphones, tablets, and computers.
                        </li>
                        <li>
                          <strong>High printing speed:</strong> <br />
                          Efficiently produces receipts, and other documents.
                        </li>
                        <li>
                          <strong>Label Prining:</strong> <br />
                          Designed to print individual labels used suitables for
                          variety of bussiness purposes
                        </li>
                        <li>
                          <strong>Compact and lightweight design:</strong>
                          <br /> Ideal for portability.
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="row short-heighlight">
                    <p className="des">Get To know More about this Product</p>
                    <span>
                      <Link
                        to={mt580Dpdatasheet}
                        target="_blank"
                        download="MT800P2.pdf"
                        className="link"
                      >
                        Download PDF
                      </Link>
                    </span>
                  </div>
                  <hr />
                </div>
              </main>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

// MT800P2